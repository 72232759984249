<script>
import translations from "@/translations.json";
import optics from "@/optics.json";

export default{
    props: [
        'city',
        'network',
        'address',
    ],
    data() {
        return {
            translationsArr: [],
            certData: {
                city: null,
                network: null,
                address: null,
            },
        }
    },
    watch: {
        '$store.state.language': {
            handler(newValue) {
                this.translationsArr = translations[newValue];

                this.certData.city = optics[this.$store.state.language][this.city]?.name;
                this.certData.network = optics[this.$store.state.language][this.city]?.network[this.network]?.name;
                this.certData.address = optics[this.$store.state.language][this.city]?.network[this.network]?.addresses[this.address]?.name;
            },
            immediate: true
        },
        'city': {
            handler(newValue) {
                this.certData.city = optics[this.$store.state.language][newValue]?.name;
            }
        },
        'network': {
            handler(newValue) {
                this.certData.network = optics[this.$store.state.language][this.city]?.network[newValue]?.name;
            }
        },
        'address': {
            handler(newValue) {
                this.certData.address = optics[this.$store.state.language][this.city]?.network[this.network]?.addresses[newValue]?.name;
            }
        }
    },
    mounted() {
        this.translationsArr = translations[this.$store.state.language]; // Инициализация

        // this.certData.city = optics[this.$store.state.language][this.city]?.name;
        // this.certData.network = optics[this.$store.state.language][this.city]?.network[this.network]?.name;
        // this.certData.address = optics[this.$store.state.language][this.city]?.network[this.network]?.addresses[this.address]?.name;
    }
}
</script>

<template>
    <section class="pdf-item flex w-full justify-center bg-[#00A3E0] min-h-[100vh] flex-col items-center">
        <div class="flex w-full flex-col items-center mt-[100px]">
            <div class="flex w-full flex-col items-center">
                <h1 class="text-center text-white text-[72px] font-black leading-[80px]" v-html="translationsArr.cert_title_1"/>
                <p class="text-center text-white text-[42px] mt-[20px] leading-[55px] px-[20px]" v-html="translationsArr.cert_title_2"/>
            </div>

            <div class="flex flex-col w-full max-w-[90%] items-center mt-[60px] border-b-2 border-white leading-[40px] text-center px-[20px] pb-[20px]">
                <p class="text-white text-[32px] font-semibold">{{ certData.network }}</p>
                <p class="text-white text-[26px] mt-[20px]">{{ certData.city }}, {{ certData.address }}</p>
            </div>
            <div class="flex w-full justify-center">
                <p class="text-white text-[32px]" v-html="translationsArr.cert_in_optic"/>
            </div>
            <div class="flex w-full justify-center mt-[27px]">
                <img class="w-full max-w-[253.2px]" src="/img/123.png" alt="">
            </div>
            <div class="flex w-full justify-center mt-[44px]">
                <p class="text-white text-[26px] px-[20px] leading-[35px] text-center" v-html="translationsArr.cert_info"/>
            </div>
        </div>

        <div class="flex w-full max-w-[1000px] px-[30px] mt-[50px] mb-[22px]">
            <p class="text-white text-[20px] font-light leading-[25px]" v-html="translationsArr.cert_footer"/>
        </div>
    </section>
</template>