<script>
import HomeView from "@/views/HomeView.vue";
import CookiesBlock from "@/components/CookiesBlock.vue";
// import PdfView from "@/views/PdfView.vue";

export default {
    name: 'App',
    components: {
        // PdfView,
        HomeView,
        CookiesBlock,
    },
    methods: {
        changeLang() {
            this.$refs.cookies.changeLang();
        }
    },
}
</script>

<template>
<!--    <PdfView city="Almaty" optic="asdasdsa" address="sdasdsacxzcvdfgdsvg"/>-->
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap" rel="stylesheet">
    <div class="flex max-w-[1920px] m-auto relative">
        <HomeView @changeLang="changeLang()"/>
        <CookiesBlock ref="cookies"/>
    </div>
</template>

<style>
#app {
    font-family: "Nunito Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
</style>
