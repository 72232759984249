import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './index.css'
import { createStore } from "vuex"

const store = createStore({
    state() {
        return {
            language: 'ru',
        }
    },
    mutations: {
        changeLang(state, lang) {
            state.language = lang;
        }
    },
    getters:{
        getLang: (state) => {
            return state.language;
        }
    }
})

createApp(App)
    .use(router)
    .use(store)
    .mount('#app')
