<script>
import translations from "@/translations.json";

export default{
    data() {
        return {
            show: false,
            translationsArr: [],
        }
    },
    methods: {
        checkAgree() {
            if (localStorage.key('agree')) {
                if (!localStorage.getItem('agree') == true) {
                    this.show = true;
                }
            } else {
                this.show = true;
            }
        },
        acceptRules() {
            localStorage.setItem('agree', true);
            this.show = false;
        },
        changeLang() {
            this.translationsArr = translations[this.$store.state.language];
        },
    },
    mounted() {
        this.checkAgree();
        this.changeLang();
    }
}
</script>

<template>
    <div v-if="show" class="flex w-full px-4 fixed bottom-[45px] left-0 justify-center z-50">
        <div class="flex flex-col sm:flex-row items-center px-[34px] 1sm:flex-row w-full max-w-[870px] 1sm:px-5 py-[16px] 1sm:py-[15px] justify-between bg-white rounded-[20px] shadow-xl gap-[20px] relative">
            <p class="text-[14px] text-[#051F4A] font-light text-center 1sm:text-left leading-[16px]" v-html="translationsArr.cookies_text"></p>
            <span class="underline"></span>
            <button @click="acceptRules()" type="button" class="flex bg-[#A51890] w-full text-[11px] max-w-[89px] 1sm:max-w-[113px] h-[32px] rounded-[37px] items-center justify-center text-white uppercase 1sm:text-[14px] font-extrabold outline outline-[2px] 1sm:mr-[40px] hover:bg-white hover:text-[#051F4A] hover:outline-[#051F4A] transition-all">
                {{ translationsArr.cookies_btn }}
            </button>
            <button @click="show = false" type="button" class="absolute top-[15px] right-[20px]">
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L9 9M9 1L1 9" stroke="#051F4A" stroke-width="2" stroke-linecap="round"/>
                </svg>
            </button>
        </div>
    </div>
</template>