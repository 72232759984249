<script>
import AppNavbar from "@/components/AppNavbar.vue";
import opticsList from "@/optics.json";
import translations from "@/translations.json";
import GeneratorContent from "@/components/GeneratorContent.vue";
import axios from "axios";


export default {
    components: {
        GeneratorContent,
        AppNavbar,
    },
    emits: [
        'changeLang',
    ],
    data() {
        return {
            form: {
                city: null,
                network: null,
                address: null,
                token: null,
                userAgent: null,
                certIsDownload: false,
            },
            optics: [],
            translationsArr: [],
            fileName: 'default',
            disableBtn: false,
        }
    },
    methods: {
        changeLang() {
            this.optics = this.sortData(opticsList[this.$store.state.language]);
            this.translationsArr = translations[this.$store.state.language];

            this.$emit('changeLang');
        },
        changeCity(event) {
            if (this.form.city != null) {
                this.form.network = null;
                this.form.address = null;
                this.form.certIsDownload = false;
                this.disableBtn = false;
            }

            this.form.city = event.target.value;

            window.dataLayer.push({
                'event': 'choose_city',
                'parameters': {
                    'city': this.form.city
                }
            });

            this.pushStat();
        },
        changeNetwork(event) {
            if (this.form.network != null) {
                this.form.address = null;
                this.form.certIsDownload = false;
                this.disableBtn = false;
            }

            this.form.network = event.target.value;

            window.dataLayer.push({
                'event': 'choose_optics',
                'parameters': {
                    'optics': this.form.network
                }
            });

            this.pushStat();
        },
        changeAddress(event) {
            if (this.form.address != null) {
                this.form.certIsDownload = false;
                this.disableBtn = false;
            }

            this.form.address = event.target.value;

            window.dataLayer.push({
                'event': 'choose_optics_adress',
                'parameters': {
                    'optics_adress': this.form.address
                }
            });

            setTimeout(() => {
                this.scrollToBlock('downloadBlock');
            }, 200);

            this.pushStat();
        },
        scrollToBlock(selector) {
            const block = document.getElementById(selector);
            if (block) {
                block.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        },
        certDownload() {
            this.disableBtn = true;
            this.form.certIsDownload = true;

            window.dataLayer.push({
                'event': 'download_certificate',
                'parameters': {
                    'city': this.form.city,
                    'optics': this.form.network,
                    'optics_adress': this.form.address
                }
            });

            this.$nextTick(() => {
                this.$refs.generator.generatePdf();
            });

            this.pushStat();

            setTimeout(() => {
                this.form.certIsDownload = false;
            }, 200);
        },
        sortData(obj) {
            if (Array.isArray(obj)) {
                return obj.map(this.sortData);
            } else if (obj !== null && typeof obj === 'object') {
                return Object.keys(obj)
                    .sort()
                    .reduce((acc, key) => {
                        acc[key] = this.sortData(obj[key]);
                        return acc;
                    }, {});
            }
            return obj;
        },
        pushStat() {
            // axios.post(`http://acuvue.admin.dvl.to/api/send`, this.form)
            axios.post(`https://xn--e1aaaaih8adate7c1b.kz/admin/api/send`, this.form)
                .then(() => {})
        },
        generateRandomStr(length) {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let result = '';
            for (let i = 0; i < length; i++) {
                const randomIndex = Math.floor(Math.random() * characters.length);
                result += characters[randomIndex];
            }
            return result + Date.now();
        },
        setUserToken() {
            if (!localStorage.getItem('acuvueToken')) {
                localStorage.setItem('acuvueToken', this.generateRandomStr(32));
            }
            this.form.token = localStorage.getItem('acuvueToken');
            this.form.userAgent = window.navigator.userAgent;
        }
    },
    mounted() {
        this.optics = this.sortData(opticsList[this.$store.state.language]);
        this.translationsArr = translations[this.$store.state.language];

        this.setUserToken();
    },
}
</script>


<template>
    <GeneratorContent ref="generator" :city="form.city" :network="form.network" :address="form.address"/>


    <div class="flex flex-col relative w-full min-h-[100vh] bg-[#00A3E0]">
        <AppNavbar @changeLang="changeLang()"/>
        <div class="flex mt-[93px] lg:hidden w-full justify-center px-[21px]">
            <button @click="scrollToBlock('formBlock')" type="button" id="scrollToFormMob" class="leading-[35px] gap-4 pt-[19px] pb-[22px] h-[98px] pulse w-full max-w-[331px] flex justify-center items-center rounded-[56px] bg-[#A51890] text-white text-[clamp(10px,_7vw,_30px)] font-extrabold"
                    style="box-shadow: 0 0 0 rgba(204,169,44, 0.4); animation: pulse 2s infinite;">
                <span v-html="translationsArr.get_cert_mob_btn"/>
                <svg width="44" height="60" viewBox="0 0 44 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M40.1169 27.2667C38.4577 27.1653 37.0112 28.0865 36.3588 29.4406V29.1044C36.3588 27.0364 34.7232 25.2309 32.6007 25.171C30.4026 25.1066 28.6015 26.8245 28.6015 28.9478V28.0266C28.6015 25.9586 26.9659 24.1532 24.8434 24.0933C22.6452 24.0288 20.8442 25.7468 20.8442 27.87V13.7902C20.8442 11.7222 19.2086 9.91676 17.086 9.85688C14.8879 9.7924 13.0868 11.5104 13.0868 13.6336V34.9767C11.196 32.7199 8.87017 30.8131 6.2513 29.3991C5.7124 29.109 5.1026 28.9524 4.48806 28.9524C2.84772 28.9524 1.36811 30.0209 0.942664 31.6468C0.526671 33.245 1.38702 34.88 2.85718 35.6906C8.85126 39.0022 11.4796 46.049 13.9046 51.9536C15.0675 54.7907 18.2537 59.8386 26.1859 59.8386H30.8564C41.4689 59.8386 43.7474 48.5868 43.7474 46.3484V45.4733V34.4148V31.1816C43.7474 29.1596 42.1827 27.391 40.1169 27.2667Z" fill="white"/>
                    <path d="M10.6851 17.0419L13.3323 13.9422" stroke="white" stroke-width="1.16" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8.60986 14.6515L12.1789 12.6157" stroke="white" stroke-width="1.16" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M7.48486 11.7176L11.555 10.9853" stroke="white" stroke-width="1.16" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M7.45654 8.58109L11.5408 9.24432" stroke="white" stroke-width="1.16" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8.5249 5.6288L12.1317 7.60006" stroke="white" stroke-width="1.16" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.5576 3.20156L13.2616 6.25518" stroke="white" stroke-width="1.16" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M13.3184 1.59415L14.798 5.36166" stroke="white" stroke-width="1.16" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M16.4761 1L16.5512 5.03004" stroke="white" stroke-width="1.16" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M19.6527 1.48361L18.3149 5.29718" stroke="white" stroke-width="1.16" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M22.4699 2.9943L19.8794 6.13543" stroke="white" stroke-width="1.16" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M24.5927 5.34324L21.0615 7.44347" stroke="white" stroke-width="1.16" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M25.7651 8.26329L21.7139 9.06469" stroke="white" stroke-width="1.16" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M25.855 11.3906L21.7612 10.8057" stroke="white" stroke-width="1.16" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M24.8434 14.3659L21.1987 12.4545" stroke="white" stroke-width="1.16" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M22.853 16.8254L20.0923 13.8224" stroke="white" stroke-width="1.16" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
        </div>

        <div class="flex flex-col lg:flex-row w-full justify-center mt-[53px] lg:mt-[170px] gap-[80px] lg:gap-[150px] items-center px-[42px] md:px-0">
            <div class="flex flex-col gap-2 text-left">
                <h1 class="hidden lg:flex text-white text-[80px] font-extrabold leading-[80px]" v-html="translationsArr.title_part1" :class="{'lg:hidden': $store.state.language === 'kz'}"/>
                <p class="text-center lg:text-left text-white text-[34px] lg:text-[48px] font-semibold leading-[40px] lg:leading-[65px]" v-html="translationsArr.title_part2"
                   :class="{
                    'text-[48px]': $store.state.language === 'kz',
                    'lg:leading-[60px]': $store.state.language === 'kz',
                    'lg:font-bold': $store.state.language === 'kz',
                    'text-[28px]': $store.state.language === 'kz',
                }"/>
            </div>
            <div class="flex">
                <div class="flex relative">
                    <div class="flex w-full flex-col gap-2 absolute top-[-40px] lg:top-0 left-0">
                        <p class="rounded-[13px] bg-[#A51890] text-white text-[19px] lg:text-[27px] font-black leading-[38px] max-w-[280px] lg:max-w-[396px] shadow-xl" v-html="translationsArr.bus_text1"/>
                        <div class="rounded-[13px] bg-[#A51890] text-white leading-[38px] font-bold max-w-[296px] lg:max-w-[420px] shadow-xl relative"
                            :class="{
                            'lg:text-[22px]': $store.state.language === 'kz',
                            'lg:text-[28px]': $store.state.language === 'ru',
                            'text-[16px]': $store.state.language === 'kz',
                            'text-[19px]': $store.state.language === 'ru',
                        }">
                            <span class="absolute bottom-[-16px] left-0 w-[20px] h-[30px]" style="background: url('/img/Vector (4).png'); background-position: 0px -25px; background-repeat: no-repeat"></span>
                            <p v-html="translationsArr.bus_text2"/>
                        </div>
                    </div>
                    <img class="max-w-[296px] w-full lg:max-w-[initial]" src="/img/bus.png" alt="">
                </div>
            </div>
        </div>

        <div class="flex w-full justify-center mt-[45px]">
            <p class="text-white text-[24px] font-extrabold lg:hidden uppercase" v-html="translationsArr.select_optic"/>
        </div>

        <div class="hidden lg:flex w-full justify-center mt-[38px] px-[21px]">
            <button @click="scrollToBlock('formBlock')" type="button" id="scrollToForm" class="pulse w-full max-w-[545px] h-[76px] flex justify-center items-center rounded-[56px] bg-[#A51890] text-white text-[32px] font-extrabold"
                    style="box-shadow: 0 0 0 rgba(204,169,44, 0.4); animation: pulse 2s infinite;" v-html="translationsArr.get_cert_btn"/>
        </div>

        <div class="flex justify-center w-full mt-[23px] lg:mt-[38px]">
            <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 19L19 36L36 19" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M2 2L19 19L36 2" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>

        <div id="formBlock" class="flex w-full flex-col items-center justify-center px-[21px]">
            <div class="flex lg:hidden w-full justify-center mt-[23px] lg:mt-[44px]">
                <p class="text-white text-[14px] px-[23px]" v-html="translationsArr.get_cert_cta"/>
            </div>

            <form class="flex flex-col w-full max-w-[690px] gap-[30px] mt-[31px] lg:mt-[44px]">
                <div class="flex bg-white w-full rounded-[50px] px-[30px] h-[59px]">
                    <select @change="changeCity($event)" name="city" id="city" class="focus-within:outline-0 w-full bg-[white] text-[24px] text-[#223758] focus-visible:outline-0">
                        <option class="text-[#223758] text-[16px] md:text-[20px] font-medium" value=""
                                :selected="!form.city" disabled :hidden="!form.city">
                            {{ translationsArr.city_placeholder }}
                        </option>
                        <option class="text-[#223758] text-[16px] md:text-[20px] font-medium" :value="key" :key="key"
                                v-for="(value, key) in optics">
                            {{ value.name }}
                        </option>
                    </select>
                </div>

                <div v-if="form.city" class="flex bg-white w-full rounded-[50px] px-[30px] h-[59px]">
                    <select @change="changeNetwork($event)" name="network" id="network" class="focus-within:outline-0 w-full bg-[white] text-[24px] text-[#223758] focus-visible:outline-0">
                        <option class="text-[#223758] text-[16px] md:text-[20px] font-medium" value=""
                                :selected="!form.network" disabled :hidden="!form.network">
                            {{ translationsArr.network_placeholder }}
                        </option>
                        <option :id="key" class="text-[#223758] text-[16px] md:text-[20px] font-medium" :value="key" :key="key"
                                v-for="(value, key) in optics[form.city]['network']">
                            {{ value.name }}
                        </option>
                    </select>
                </div>

                <div v-if="form.network" class="flex bg-white w-full rounded-[50px] px-[30px] h-[59px]">
                    <select @change="changeAddress($event)" name="address" id="address" class="focus-within:outline-0 w-full bg-[white] text-[24px] text-[#223758] focus-visible:outline-0">
                        <option class="text-[#223758] text-[16px] md:text-[20px] font-medium" value=""
                                :selected="!form.address" disabled :hidden="!form.address">
                            {{ translationsArr.address_placeholder }}
                        </option>
                        <option :id="key" class="text-[#223758] text-[16px] md:text-[20px] font-medium" :value="key" :key="key"
                                v-for="(value, key) in optics[form.city]['network'][form.network]['addresses']">
                            {{ value.name }}
                        </option>
                    </select>
                </div>
            </form>
        </div>

        <div
            v-if="form.address"
            id="downloadBlock"
            class="flex w-full flex-col gap-[25px] lg:gap-[50px] items-center mt-[81px] lg:mt-[120px] px-[21px]">
            <p class="font-bold text-[24px] lg:text-[42px] text-white leading-[30px] lg:leading-[45px]" v-html="translationsArr.download_block_info"/>

            <button :disabled="disableBtn" @click="certDownload" type="button" class="pulse flex justify-center items-center gap-[25px] px-[25px] lg:px-[41px] py-[9px] bg-[#A51890] rounded-[56px]"
                    style="box-shadow: 0 0 0 rgba(204,169,44, 0.4); animation: pulse 2.5s infinite;">

                <span class="text-[19px] lg:text-[32px] text-white font-extrabold" v-html="translationsArr.download_cert_btn"/>

                <svg class="w-[26px] h-[30px] lg:w-[44px] lg:h-[60px]" viewBox="0 0 44 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M40.1169 27.2667C38.4577 27.1653 37.0112 28.0865 36.3588 29.4406V29.1044C36.3588 27.0364 34.7232 25.2309 32.6007 25.171C30.4026 25.1066 28.6015 26.8245 28.6015 28.9478V28.0266C28.6015 25.9586 26.9659 24.1532 24.8434 24.0933C22.6452 24.0288 20.8442 25.7468 20.8442 27.87V13.7902C20.8442 11.7222 19.2086 9.91676 17.086 9.85688C14.8879 9.7924 13.0868 11.5104 13.0868 13.6336V34.9767C11.196 32.7199 8.87017 30.8131 6.2513 29.3991C5.7124 29.109 5.1026 28.9524 4.48806 28.9524C2.84772 28.9524 1.36811 30.0209 0.942664 31.6468C0.526671 33.245 1.38702 34.88 2.85718 35.6906C8.85126 39.0022 11.4796 46.049 13.9046 51.9536C15.0675 54.7907 18.2537 59.8386 26.1859 59.8386H30.8564C41.4689 59.8386 43.7474 48.5868 43.7474 46.3484V45.4733V34.4148V31.1816C43.7474 29.1596 42.1827 27.391 40.1169 27.2667Z" fill="white"/>
                    <path d="M10.6851 17.0419L13.3323 13.9422" stroke="white" stroke-width="1.16" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8.60986 14.6515L12.1789 12.6157" stroke="white" stroke-width="1.16" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M7.48486 11.7176L11.555 10.9853" stroke="white" stroke-width="1.16" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M7.45654 8.58109L11.5408 9.24432" stroke="white" stroke-width="1.16" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8.5249 5.6288L12.1317 7.60006" stroke="white" stroke-width="1.16" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.5576 3.20156L13.2616 6.25518" stroke="white" stroke-width="1.16" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M13.3184 1.59415L14.798 5.36166" stroke="white" stroke-width="1.16" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M16.4761 1L16.5512 5.03004" stroke="white" stroke-width="1.16" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M19.6527 1.48361L18.3149 5.29718" stroke="white" stroke-width="1.16" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M22.4699 2.9943L19.8794 6.13543" stroke="white" stroke-width="1.16" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M24.5927 5.34324L21.0615 7.44347" stroke="white" stroke-width="1.16" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M25.7651 8.26329L21.7139 9.06469" stroke="white" stroke-width="1.16" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M25.855 11.3906L21.7612 10.8057" stroke="white" stroke-width="1.16" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M24.8434 14.3659L21.1987 12.4545" stroke="white" stroke-width="1.16" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M22.853 16.8254L20.0923 13.8224" stroke="white" stroke-width="1.16" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>

            <p class="text-white text-18px lg:text-[30px] leading-[20px] lg:leading-[30px]" v-html="translationsArr.download_block_info2"/>
        </div>

        <div class="flex w-full mt-[39px] lg:mt-[130px] mb-[20px] justify-center px-[21px]">
            <p class="text-white text-[12px] leading-[14px] text-center w-full max-w-[1050px] md:text-left" v-html="translationsArr.footer_text"/>
        </div>
    </div>
</template>

<style>
.pulse:hover {
    animation: none;
}

@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    }
}
@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgb(165, 24, 144);
        box-shadow: 0 0 0 0 rgb(165, 24, 144);
    }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
        box-shadow: 0 0 0 10px rgba(204,169,44, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
        box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    }
}

button:disabled{
    @apply bg-gray-400 cursor-no-drop;
}
</style>