<script>
import PdfView from "@/views/PdfView.vue";
import Vue3Html2pdf from 'vue3-html2pdf';

export default {
    props: [
        'city',
        'network',
        'address',
        'fileName',
    ],
    components: {
        PdfView,
        Vue3Html2pdf,
    },
    methods: {
        generatePdf() {
            this.$refs.html2Pdf.generatePdf();
        }
    }
}
</script>

<template>
    <vue3-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="0"
        :filename="fileName"
        :pdf-quality="3"
        :manual-pagination="true"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="100%"
        ref="html2Pdf"
    >
        <template v-slot:pdf-content>
            <PdfView :city="city" :network="network" :address="address"/>
        </template>
    </vue3-html2pdf>
</template>